<template>
  <div class="entry max-w-7xl mx-auto my-8 px-2">
    <h2 class="text-bold text-3xl">Arkansas AI-Campus</h2>
    <!-- <p class="text-bold">10th October 2018</p> -->
    <p class="pt-4">
      The Arkansas AI-Campus is a unique statewide training program. This
      program, which will exist outside of our traditional academic environment,
      will be free of charge and participation will be voluntary.
    </p>
    <p class="pt-4">
      This interactive training program will allow students to work closely with
      top global and national experts in the areas of:
    </p>
    <p class="pt-4"></p>
    <div class=""><span class="text-bold">1.</span> Machine Learning</div>
    <div class="">
      <span class="text-bold">2.</span> Artificial Intelligence
    </div>
    <div class=""><span class="text-bold">3.</span> Deep Learning</div>
    <p class="pt-4">
      The experts who hold top records in international competitions, such as
      Data Science Bowl (DSB) and DREAM Challenges, will serve as volunteer
      coaches, providing frontier knowledge and technology, and overseeing
      hands-on projects.
    </p>
    <p class="">
      The training experience consists of two 4-month phases and is provided
      completely free of charge to qualified students, including senior
      undergraduates and graduates from diverse academic programs and
      disciplines from various Arkansas institutions.
    </p>
    <p class="pt-4">
      <b>Phase 1:</b> Students (about 20-30) will spend 4 months working with
      mentors on guided example projects, culminating in a capstone project of
      the students choosing. The student will earn a certificate of completion
      that can be added to a graduate school application or CV.
    </p>
    <p class="pt-4">
      <b>Phase 2:</b> Selected students (about 10) from Phase 1 will be invited
      to continue for an additional 4 months. In Phase 2, students will work
      directly with a coach and participate in national and worldwide machine
      learning competitions, as well as potential focused research projects.
      Every two students and a coach with top records in previous competitions
      will form a team; we plan to have 5 teams.
    </p>
    <p class="pt-4">
      After the free one-year training, each of the students will decide whether
      or not to continue to work with the Arkansas AI-Campus; Based on the
      students' interests, the Board of the Arkansas AI-Campus will discuss with
      the students potential research projects in various areas with potential
      funding support. Later, the 10 students are expected to be experts in
      machine learning and AI.
    </p>
    <p class="pt-4">
      <i>Note:</i> AI-Campus will support all institutions in Arkansas in making
      their efforts to incorporate AI into their existing academic education
      program, such as, develop "AI Education Certificate". Students directly
      trained through the AI-Campus will get AI Challenge Certificate for those
      who complete Phase 1 training, and AI Expert Certificate for those who
      complete Phase 2 training.
    </p>
    <p class="pt-4">
      <i>Note:</i> Besides senior undergraduates and graduates, the free
      training of AI-Campus with national top experts also welcomes junior
      faculty and hospital residents to join.
    </p>
    <p class="pt-4">
      Applications will be accepted through the
      <a href="https://astate.infoready4.com/#manageCompetitionsDetail/1777306"
        >A-State InfoReady System</a
      >, under <b>Arkansas AI Campus Student Recommendation</b>.
    </p>
    <p class="meta">Build Arkansas AI strength, bring AI close to everyone!</p>
  </div>
</template>

<script>
export default {
  methods: {},
  setup() {
    return {
      years: {
        _2021: [
          {
            title: "CNBT Expands AI Campus Iniative Across Arkansas",
            date: 3,
          },
        ],
        _2020: [
          {
            title:
              "ARKANSAS AI-CAMPUS CONTINUES WORK VIRTUALLY DURING COVID-19 PANDEMIC",
            month: "April",
            link: "https://armoneyandpolitics.com/arkansas-ai-campus-continues-work-virtually-during-covid-19-pandemic/",
            date: 13,
          },
        ],
        _2019: [
          {
            title:
              "Artificial Intelligence Campus Demonstration Held In Little Rock",
            month: "February",
            day: 20,
            link: "https://aralliance.org/artificial-intelligence-campus-demonstration-held-in-little-rock/",
          },
          {
            title:
              "RESEARCHERS PRESENT ARTIFICIAL INTELLIGENCE PROJECT IN LITTLE ROCK",
            month: "February",
            day: 18,
            link: "https://aralliance.org/artificial-intelligence-campus-demonstration-held-in-little-rock/",
          },
          {
            title: "Part 2: AI Projects Demonstations in Little Rock",
            month: "February",
            day: 15,
            link: "https://www.facebook.com/johnathan.reaves/videos/2385446874823181/",
          },
          {
            title: "Part 1: AI Projects Demonstations in Little Rock",
            month: "February",
            day: 15,
            link: "https://www.facebook.com/johnathan.reaves/videos/2385148401519695/",
          },
          {
            title: "ARTIFICIAL INTELLIGENCE PROJECTS TO BE SHOWCASED FRIDAY",
            month: "February",
            day: 14,
            link: "https://aralliance.org/artificial-intelligence-campus-demonstration-held-in-little-rock/",
          },
        ],
        _2018: [
          {
            html: `<div class="entry">
<h2 class="entry-title">Arkansas AI-Campus</h2>
<p class="meta">10th October 2018</p>
<p>The Arkansas AI-Campus is a unique statewide training program. This program, which will exist outside of our traditional academic environment, will be free of charge and participation will be voluntary.</p>
<p>
This interactive training program will allow students to work closely with top global and national experts in the areas of:</p>
<p>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>1.</b> Machine Learning<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>2.</b> Artificial Intelligence<br>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>3.</b> Deep Learning<br>
<p>
The experts who hold top records in international competitions, such as Data Science Bowl (DSB) and DREAM Challenges, will serve as volunteer coaches, providing frontier knowledge and technology, and overseeing hands-on projects.</p>
<p>
The training experience consists of two 4-month phases and is provided completely free of charge to qualified students, including senior undergraduates and graduates from diverse academic programs and disciplines from various Arkansas institutions.</p>
<p>
<b>Phase 1:</b> Students (about 20-30) will spend 4 months working with mentors on guided example projects, culminating in a capstone project of the students choosing. The student will earn a certificate of completion that can be added to a graduate school application or CV.</p>
<p>
<b>Phase 2:</b> Selected students (about 10) from Phase 1 will be invited to continue for an additional 4 months. In Phase 2, students will work directly with a coach and participate in national and worldwide machine learning competitions, as well as potential focused research projects. Every two students and a coach with top records in previous competitions will form a team; we plan to have 5 teams.</p>
<p>
After the free one-year training, each of the students will decide whether or not to continue to work with the Arkansas AI-Campus; Based on the students' interests, the Board of the Arkansas AI-Campus will discuss with the students potential research projects in various areas with potential funding support. Later, the 10 students are expected to be experts in machine learning and AI.</p>
<p>
<i>Note:</i> AI-Campus will support all institutions in Arkansas in making their efforts to incorporate AI into their existing academic education program, such as, develop "AI Education Certificate". Students directly trained through the AI-Campus will get AI Challenge Certificate for those who complete Phase 1 training, and AI Expert Certificate for those who complete Phase 2 training.</p>
<p>
<i>Note:</i> Besides senior undergraduates and graduates, the free training of AI-Campus with national top experts also welcomes junior faculty and hospital residents to join.</p>
<p>
Applications will be accepted through the <a href="https://astate.infoready4.com/#manageCompetitionsDetail/1777306">A-State InfoReady System</a>, under <b>Arkansas AI Campus Student Recommendation</b>.</p>
<p class="meta">
Build Arkansas AI strength, bring AI close to everyone!
</p>
</div>`,
            month: "October",
            date: 10,
          },
        ],
      },
    };
  },

  metaInfo: {
    title: "About Us",
  },
};
</script>
